/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/nextjs';
import NextHead from 'next/head';
import ErrorPage from 'next/error';
import {
  GetStaticProps,
  GetStaticPaths,
  InferGetStaticPropsType,
  GetStaticPathsContext,
} from 'next';
import Head from '@components/Head';
import Layout from '@components/Layout';
import NewsList from '@components/NewsList';
import { STAGING, ROUTE_NEWS, REVALIDATE_VALUES } from '@constants';
import getUrlByEnv from '@utils/get-url-by-env';
import decoratePageContent from '@utils/decorate-page-content';
import getNav from '@framework/api/operations/get-nav';
import getPage from '@framework/api/operations/get-page';
import listNews from '@framework/api/operations/list-news';
import getNewsCategory from '@framework/api/operations/get-news-category';
import getAllNewsCategories from '@framework/api/operations/get-all-news-categories';
import PageComponent from '@components/Page';

export const getStaticProps: GetStaticProps = async ({
  locale,
  params,
  preview,
}) => {
  const envName =
    process.env.VERCEL_GITHUB_COMMIT_REF === STAGING
      ? process.env.NEXT_PUBLIC_STAGING_ENV_NAME
      : process.env.NEXT_PUBLIC_ENV_NAME;

  const baseUrl = getUrlByEnv(envName);

  const page = Array.isArray(params.page)
    ? parseInt(params.page[0], 10)
    : parseInt(params.page, 10);

  const [{ articles, totalPages }, nav, pageData, allCategories] =
    await Promise.all([
      listNews({
        page,
        locale,
        preview,
        fallbackToEnglish: true,
      }),
      getNav({ slug: 'main', locale, preview }),
      getPage({ slug: 'news', locale, preview }),
      getAllNewsCategories({ locale, preview }),
    ]);

  const categoryOptions = allCategories.map(c => ({
    label: c.category,
    value: c.slug,
  }));

  // add an all items option to select, since we're not on a filtered page
  categoryOptions.unshift({ label: 'All', value: '' });

  // TODO - find a way to get the correct default images per category?
  // this is a terrible placeholder
  const categoryData = await getNewsCategory({
    slug: 'product-launch',
    locale,
    preview,
  });

  if (!articles) {
    Sentry.captureException(
      new Error(`News articles for page '${page}' not found`)
    );

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  if (!pageData) {
    Sentry.captureException(new Error(`Dato Page with slug 'news' not found`));

    return {
      notFound: true,
      revalidate: REVALIDATE_VALUES.notFound,
    };
  }

  const { content, ...rest } = pageData;

  const decoratedContent = await decoratePageContent(content);

  return {
    props: {
      preview: preview || false,
      baseUrl,
      articles,
      currentPage: page,
      totalPages,
      nav,
      pageData: { content: decoratedContent, ...rest },
      categoryOptions,
      categoryData,
    },
    revalidate: REVALIDATE_VALUES.standard,
  };
};

export const getStaticPaths: GetStaticPaths = async ({
  defaultLocale,
}: GetStaticPathsContext) => {
  const { totalPages } = await listNews({
    page: 1,
    locale: defaultLocale,
    preview: false,
  });

  return {
    paths: Array.from({ length: totalPages }, (_, index) => {
      const page = index + 1;
      return `/${ROUTE_NEWS}/page/${page}`;
    }),
    fallback: 'blocking',
  };
};

const NewsListPage: InferGetStaticPropsType<typeof getStaticProps> = ({
  baseUrl,
  articles,
  currentPage,
  totalPages,
  pageData,
  categoryOptions,
  categoryData,
}) => {
  // Set noindex as static files always return 200 but the 404 shouldnt be indexed
  if (articles.length === 0) {
    return (
      <>
        <NextHead>
          <meta name="robots" content="noindex" />
        </NextHead>
        <ErrorPage statusCode={404} />
      </>
    );
  }

  return (
    <>
      <Head
        baseUrl={baseUrl}
        canonical={`${baseUrl}/${ROUTE_NEWS}/page/${currentPage}`}
        cmsData={pageData.seo}
      />
      <NewsList
        categoryOptions={categoryOptions}
        title={pageData.title}
        articles={articles}
        totalPages={totalPages}
        currentPage={currentPage}
        routePrefix={`/${ROUTE_NEWS}/page`}
        defaultHeroCardImage={categoryData.defaultHeroCardImage}
        defaultImage={categoryData.defaultImage}
      />
      <PageComponent page={pageData} />
    </>
  );
};

NewsListPage.Layout = Layout;

export default NewsListPage;
